import { Player } from '../../types/player';
import {
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';

interface PlayerCardProps {
  player: Player;
  onEdit: (player: Player) => void;
  onDelete: (player: Player) => void;
}

export default function PlayerCard({ player, onEdit, onDelete }: PlayerCardProps) {
  return (
    <div className="card p-6 hover:border-primary-500/50 transition-colors duration-300">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-4">
          <img
            src={player.profileImage}
            alt={player.name}
            className="w-16 h-16 rounded-full border-2 border-dark-700"
          />
          <div>
            <h3 className="text-lg font-medium text-gray-100">{player.name}</h3>
            <div className="flex items-center space-x-2 mt-1">
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                player.status === 'active' 
                  ? 'bg-emerald-400/10 text-emerald-400' 
                  : 'bg-red-400/10 text-red-400'
              }`}>
                {player.status.charAt(0).toUpperCase() + player.status.slice(1)}
              </span>
              {player.currentTeam && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-400/10 text-primary-400">
                  {player.currentTeam}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => onEdit(player)}
            className="btn-secondary text-sm px-3 py-1"
          >
            Edit
          </button>
          <button
            onClick={() => onDelete(player)}
            className="px-3 py-1 bg-red-500/10 text-red-400 rounded-lg hover:bg-red-500/20 transition-colors duration-200 text-sm"
          >
            Delete
          </button>
        </div>
      </div>

      <div className="mt-6 space-y-3">
        <div className="flex items-center space-x-2 text-gray-400">
          <EnvelopeIcon className="w-4 h-4" />
          <span className="text-sm">{player.email}</span>
        </div>
        <div className="flex items-center space-x-2 text-gray-400">
          <PhoneIcon className="w-4 h-4" />
          <span className="text-sm">{player.phone}</span>
        </div>
        <div className="flex items-start space-x-2 text-gray-400">
          <MapPinIcon className="w-4 h-4 mt-1 flex-shrink-0" />
          <div className="text-sm">
            <div>{player.address.street}</div>
            <div>{player.address.city}, {player.address.state} {player.address.zipCode}</div>
          </div>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-4 gap-4">
        <div className="text-center">
          <div className="text-2xl font-semibold text-primary-400">
            {player.eloRating}
          </div>
          <div className="text-xs text-gray-400 mt-1">Elo Rating</div>
        </div>
        <div className="text-center">
          <div className="text-2xl font-semibold text-emerald-400">
            {player.wins}
          </div>
          <div className="text-xs text-gray-400 mt-1">Wins</div>
        </div>
        <div className="text-center">
          <div className="text-2xl font-semibold text-red-400">
            {player.losses}
          </div>
          <div className="text-xs text-gray-400 mt-1">Losses</div>
        </div>
        <div className="text-center">
          <div className="text-2xl font-semibold text-yellow-400">
            {player.totalPoints}
          </div>
          <div className="text-xs text-gray-400 mt-1">Points</div>
        </div>
      </div>
    </div>
  );
}
