import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Player } from '../../types/player';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icons in react-leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface PlayerMapProps {
  players: Player[];
  onPlayerClick?: (player: Player) => void;
}

export default function PlayerMap({ players, onPlayerClick }: PlayerMapProps) {
  // Find center point of all players
  const center = players.reduce(
    (acc, player) => {
      if (player.address.coordinates) {
        acc.lat += player.address.coordinates.lat;
        acc.lng += player.address.coordinates.lng;
      }
      return acc;
    },
    { lat: 0, lng: 0 }
  );

  if (players.length > 0) {
    center.lat /= players.length;
    center.lng /= players.length;
  } else {
    // Default to US center if no players
    center.lat = 39.8283;
    center.lng = -98.5795;
  }

  return (
    <div className="card overflow-hidden">
      <MapContainer
        center={[center.lat, center.lng]}
        zoom={4}
        style={{ height: '600px', width: '100%' }}
        className="z-0"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {players.map((player) => {
          if (!player.address.coordinates) return null;
          
          return (
            <Marker
              key={player.id}
              position={[player.address.coordinates.lat, player.address.coordinates.lng]}
              eventHandlers={{
                click: () => onPlayerClick?.(player)
              }}
            >
              <Popup>
                <div className="p-2">
                  <h3 className="font-medium text-dark-900">{player.name}</h3>
                  <p className="text-sm text-dark-600">{player.address.street}</p>
                  <p className="text-sm text-dark-600">
                    {player.address.city}, {player.address.state} {player.address.zipCode}
                  </p>
                  <div className="mt-2 text-sm">
                    <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                      player.status === 'active' 
                        ? 'bg-emerald-100 text-emerald-800' 
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {player.status}
                    </span>
                  </div>
                </div>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
}
