import { useState, useEffect, useCallback } from 'react';
import { Player, PlayerFormData } from '../types/player';
import { playerService } from '../services/playerService';
import PlayerCard from '../components/players/PlayerCard';
import PlayerForm from '../components/players/PlayerForm';
import PlayerMap from '../components/players/PlayerMap';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  FunnelIcon,
  MapIcon,
  ViewColumnsIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';

type ViewMode = 'grid' | 'map';

export default function Players() {
  const [players, setPlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  
  // Filters
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<'all' | 'active' | 'inactive'>('all');
  
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | undefined>(undefined);
  
  // Delete confirmation
  const [playerToDelete, setPlayerToDelete] = useState<Player | null>(null);

  const loadPlayers = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const filters: any = {};
      if (searchQuery) filters.search = searchQuery;
      if (statusFilter !== 'all') filters.status = statusFilter;
      
      const data = await playerService.getPlayers(filters);
      setPlayers(data);
    } catch (err) {
      setError('Failed to load players. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [searchQuery, statusFilter]);

  useEffect(() => {
    loadPlayers();
  }, [loadPlayers]);

  const handleAddPlayer = () => {
    setSelectedPlayer(undefined);
    setIsModalOpen(true);
  };

  const openNewPlayerModal = () => {
    setSelectedPlayer(undefined);
    setIsModalOpen(true);
  };

  const handleEditPlayer = (player: Player) => {
    setSelectedPlayer(player);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (player: Player) => {
    setPlayerToDelete(player);
  };

  const handleDeleteConfirm = async () => {
    if (!playerToDelete) return;
    
    try {
      setActionInProgress(true);
      await playerService.deletePlayer(playerToDelete.id);
      await loadPlayers();
      setPlayerToDelete(null);
    } catch (err) {
      setError('Failed to delete player. Please try again.');
      console.error(err);
    } finally {
      setActionInProgress(false);
    }
  };

  const handleSubmit = async (data: PlayerFormData) => {
    try {
      setActionInProgress(true);
      if (selectedPlayer) {
        await playerService.updatePlayer(selectedPlayer.id, data);
      } else {
        await playerService.createPlayer(data);
      }
      await loadPlayers();
      setIsModalOpen(false);
      setSelectedPlayer(undefined);
    } catch (err) {
      throw new Error(selectedPlayer ? 'Failed to update player' : 'Failed to create player');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleCloseModal = () => {
    if (!actionInProgress) {
      setIsModalOpen(false);
      setSelectedPlayer(undefined);
    }
  };

  const toggleViewMode = () => {
    setViewMode(current => current === 'grid' ? 'map' : 'grid');
  };

  return (
    <>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold text-gray-100">Players</h1>
          <div className="flex items-center space-x-3">
            <button
              onClick={toggleViewMode}
              className="btn-secondary flex items-center space-x-2"
            >
              {viewMode === 'grid' ? (
                <>
                  <MapIcon className="w-5 h-5" />
                  <span>Map View</span>
                </>
              ) : (
                <>
                  <ViewColumnsIcon className="w-5 h-5" />
                  <span>Grid View</span>
                </>
              )}
            </button>
            <button
              onClick={handleAddPlayer}
              className="btn-primary flex items-center space-x-2"
              disabled={actionInProgress}
            >
              <PlusIcon className="w-5 h-5" />
              <span>Add Player</span>
            </button>
            {/* New Create Player Button */}
            <button
              onClick={openNewPlayerModal}
              className="btn-primary flex items-center space-x-2 bg-green-600 hover:bg-green-700"
              disabled={actionInProgress}
            >
              <UserPlusIcon className="w-5 h-5" />
              <span>Create New Player</span>
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="card p-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search players..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="input pl-10"
                />
              </div>
            </div>
            <div className="sm:w-48">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value as any)}
                className="input"
              >
                <option value="all">All Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="rounded-lg bg-red-500/10 p-4 text-red-400">
            {error}
            <button
              onClick={loadPlayers}
              className="ml-2 underline hover:text-red-300"
            >
              Try again
            </button>
          </div>
        )}

        {/* Loading State */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500 mx-auto"></div>
            <p className="mt-4 text-gray-400">Loading players...</p>
          </div>
        ) : players.length === 0 ? (
          <div className="text-center py-12">
            <div className="w-16 h-16 bg-dark-700 rounded-full flex items-center justify-center mx-auto mb-4">
              <FunnelIcon className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-300">No players found</h3>
            <p className="text-gray-400 mt-2">Try adjusting your filters or add a new player.</p>
            <button
              onClick={openNewPlayerModal}
              className="btn-primary mt-6"
            >
              Add Your First Player
            </button>
          </div>
        ) : viewMode === 'grid' ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {players.map((player) => (
              <PlayerCard
                key={player.id}
                player={player}
                onEdit={handleEditPlayer}
                onDelete={handleDeleteClick}
              />
            ))}
          </div>
        ) : (
          <PlayerMap
            players={players}
            onPlayerClick={handleEditPlayer}
          />
        )}
      </div>

      {/* Modals Container */}
      <div className="relative z-50">
        {/* Add/Edit Modal */}
        {isModalOpen && (
          <PlayerForm
            player={selectedPlayer}
            onSubmit={handleSubmit}
            onClose={handleCloseModal}
          />
        )}

        {/* Delete Confirmation Modal */}
        {playerToDelete && (
          <div className="fixed inset-0 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
            <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-md p-6 animate-fadeInUp">
              <h3 className="text-lg font-medium text-gray-100 mb-4">Delete Player</h3>
              <p className="text-gray-400">
                Are you sure you want to delete {playerToDelete.name}? This action cannot be undone.
              </p>
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => setPlayerToDelete(null)}
                  className="btn-secondary"
                  disabled={actionInProgress}
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteConfirm}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors relative"
                  disabled={actionInProgress}
                >
                  {actionInProgress ? (
                    <>
                      <span className="opacity-0">Delete</span>
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-5 h-5 border-t-2 border-r-2 border-white rounded-full animate-spin"></div>
                      </div>
                    </>
                  ) : (
                    'Delete'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
