import { Court, CreateCourtDTO, UpdateCourtDTO } from '../types/court';

// Simulated database
let courts: Court[] = [];

export const courtService = {
  getCourts: async (): Promise<Court[]> => {
    return courts;
  },

  getCourt: async (id: string): Promise<Court | undefined> => {
    return courts.find(court => court.id === id);
  },

  createCourt: async (data: CreateCourtDTO): Promise<Court> => {
    const court: Court = {
      id: Math.random().toString(36).substr(2, 9),
      ...data,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    courts.push(court);
    return court;
  },

  updateCourt: async (id: string, data: UpdateCourtDTO): Promise<Court> => {
    const index = courts.findIndex(court => court.id === id);
    if (index === -1) throw new Error('Court not found');

    const updatedCourt = {
      ...courts[index],
      ...data,
      updatedAt: new Date().toISOString(),
    };
    courts[index] = updatedCourt;
    return updatedCourt;
  },

  deleteCourt: async (id: string): Promise<void> => {
    courts = courts.filter(court => court.id !== id);
  },
};
