import React from 'react';
import { Match } from '../../types/match';
import { XMarkIcon, MapPinIcon, UserGroupIcon, CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';

interface MatchModalProps {
  match: Match;
  onClose: () => void;
}

const ScoreDisplay = ({ score }: { score: string }) => {
  const sets = score.split(', ').map(set => {
    const [score1, score2] = set.split('-').map(Number);
    return { score1, score2, winner: score1 > score2 ? 1 : 2 };
  });

  return (
    <div className="flex space-x-2">
      {sets.map((set, idx) => (
        <div key={idx} className="flex items-center space-x-1">
          <div className={`px-3 py-2 rounded ${
            set.winner === 1 
              ? 'bg-primary-500/20 text-primary-400 font-medium' 
              : 'bg-dark-700 text-gray-400'
          }`}>
            {set.score1}
          </div>
          <div className={`px-3 py-2 rounded ${
            set.winner === 2 
              ? 'bg-primary-500/20 text-primary-400 font-medium' 
              : 'bg-dark-700 text-gray-400'
          }`}>
            {set.score2}
          </div>
          {idx < sets.length - 1 && (
            <span className="text-gray-600">•</span>
          )}
        </div>
      ))}
    </div>
  );
};

export const MatchModal: React.FC<MatchModalProps> = ({ match, onClose }) => {
  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
    const day = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
    return { month, day, year, time };
  };

  const { month, day, year, time } = formatDate(match.startTime);

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-900 bg-opacity-75" onClick={onClose} />

        <div className="relative inline-block w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-dark-800 rounded-2xl">
          <div className="absolute top-4 right-4">
            <button
              onClick={onClose}
              className="p-2 rounded-lg hover:bg-dark-700 transition-colors"
            >
              <XMarkIcon className="w-6 h-6 text-gray-400" />
            </button>
          </div>

          <div className="flex items-center space-x-6 mb-8">
            <div className="text-center">
              <div className="text-sm font-bold tracking-wider text-primary-400">{month}</div>
              <div className="text-4xl font-bold text-gray-100 -mt-1">{day}</div>
              <div className="text-xs font-medium text-gray-500 -mt-1">{year}</div>
            </div>
            <div className="h-16 w-px bg-dark-700"></div>
            <div>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                match.type === 'singles'
                  ? 'bg-blue-500/20 text-blue-400'
                  : 'bg-purple-500/20 text-purple-400'
              }`}>
                {match.type === 'singles' ? 'Singles Match' : 'Doubles Match'}
              </span>
              <div className="flex items-center space-x-2 mt-2">
                <ClockIcon className="w-4 h-4 text-gray-500" />
                <span className="text-gray-400">{time}</span>
              </div>
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex items-center space-x-4 p-4 rounded-lg bg-dark-700">
              <MapPinIcon className="w-6 h-6 text-blue-400" />
              <div>
                <p className="text-gray-200 font-medium">{match.court}</p>
                <p className="text-gray-400 text-sm mt-1">{match.address}</p>
              </div>
            </div>

            <div className="p-4 rounded-lg bg-dark-700">
              <div className="flex items-center space-x-2 mb-4">
                <UserGroupIcon className="w-6 h-6 text-purple-400" />
                <h3 className="text-lg font-medium text-gray-200">
                  {match.type === 'singles' ? 'Players' : 'Teams'}
                </h3>
              </div>

              {match.type === 'singles' ? (
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <p className="text-gray-200 font-medium">{match.player1?.name}</p>
                    {match.outcome && match.outcome.winner === 'player1' && (
                      <span className="text-primary-400 text-sm font-medium">Winner</span>
                    )}
                  </div>
                  <div className="w-full h-px bg-dark-600"></div>
                  <div className="flex items-center justify-between">
                    <p className="text-gray-200 font-medium">{match.player2?.name}</p>
                    {match.outcome && match.outcome.winner === 'player2' && (
                      <span className="text-primary-400 text-sm font-medium">Winner</span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="space-y-6">
                  <div>
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-gray-400 text-sm">Team 1</p>
                      {match.outcome && match.outcome.winner === 'team1' && (
                        <span className="text-primary-400 text-sm font-medium">Winner</span>
                      )}
                    </div>
                    <p className="text-gray-200">{match.team1?.players.map(p => p.name).join(', ')}</p>
                  </div>
                  <div className="w-full h-px bg-dark-600"></div>
                  <div>
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-gray-400 text-sm">Team 2</p>
                      {match.outcome && match.outcome.winner === 'team2' && (
                        <span className="text-primary-400 text-sm font-medium">Winner</span>
                      )}
                    </div>
                    <p className="text-gray-200">{match.team2?.players.map(p => p.name).join(', ')}</p>
                  </div>
                </div>
              )}

              {match.outcome && (
                <div className="mt-6 pt-6 border-t border-dark-600">
                  <h4 className="text-gray-400 text-sm mb-3">Final Score</h4>
                  <ScoreDisplay score={match.outcome.score} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
