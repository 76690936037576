import { Player, PlayerFormData } from '../types/player';

// Mock data
const mockPlayers: Player[] = [
  {
    id: '1',
    name: 'John Smith',
    email: 'john.smith@email.com',
    phone: '(555) 123-4567',
    eloRating: 1850,
    totalPoints: 2500,
    status: 'active',
    joinDate: '2023-08-15',
    matchesPlayed: 45,
    wins: 30,
    losses: 15,
    currentTeam: 'Riverside Smashers',
    profileImage: 'https://ui-avatars.com/api/?name=John+Smith&background=0D9488&color=fff',
    address: {
      street: '123 Main St',
      city: 'San Francisco',
      state: 'CA',
      zipCode: '94105',
      coordinates: {
        lat: 37.7749,
        lng: -122.4194
      }
    }
  },
  {
    id: '2',
    name: 'Sarah Davis',
    email: 'sarah.davis@email.com',
    phone: '(555) 234-5678',
    eloRating: 1920,
    totalPoints: 3200,
    status: 'active',
    joinDate: '2023-06-20',
    matchesPlayed: 52,
    wins: 38,
    losses: 14,
    currentTeam: 'Downtown Champions',
    profileImage: 'https://ui-avatars.com/api/?name=Sarah+Davis&background=0D9488&color=fff',
    address: {
      street: '456 Market St',
      city: 'Los Angeles',
      state: 'CA',
      zipCode: '90012',
      coordinates: {
        lat: 34.0522,
        lng: -118.2437
      }
    }
  },
  {
    id: '3',
    name: 'Mike Johnson',
    email: 'mike.johnson@email.com',
    phone: '(555) 345-6789',
    eloRating: 1750,
    totalPoints: 1800,
    status: 'inactive',
    joinDate: '2023-09-10',
    matchesPlayed: 28,
    wins: 15,
    losses: 13,
    profileImage: 'https://ui-avatars.com/api/?name=Mike+Johnson&background=0D9488&color=fff',
    address: {
      street: '789 Broadway',
      city: 'New York',
      state: 'NY',
      zipCode: '10003',
      coordinates: {
        lat: 40.7128,
        lng: -74.0060
      }
    }
  }
];

// Simulate API delay
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Simulate geocoding
const mockGeocode = async (address: string): Promise<{lat: number, lng: number}> => {
  await delay(500);
  // In a real app, this would call a geocoding service
  return {
    lat: 40.7128 + (Math.random() - 0.5) * 0.1,
    lng: -74.0060 + (Math.random() - 0.5) * 0.1
  };
};

export const playerService = {
  // Get all players with optional filtering
  getPlayers: async (filters?: {
    status?: 'active' | 'inactive';
    search?: string;
    team?: string;
  }): Promise<Player[]> => {
    await delay(500);

    let filteredPlayers = [...mockPlayers];

    if (filters) {
      if (filters.status) {
        filteredPlayers = filteredPlayers.filter(p => p.status === filters.status);
      }
      if (filters.search) {
        const searchLower = filters.search.toLowerCase();
        filteredPlayers = filteredPlayers.filter(p =>
          p.name.toLowerCase().includes(searchLower) ||
          p.email.toLowerCase().includes(searchLower) ||
          p.address.city.toLowerCase().includes(searchLower)
        );
      }
      if (filters.team) {
        filteredPlayers = filteredPlayers.filter(p => p.currentTeam === filters.team);
      }
    }

    return filteredPlayers;
  },

  // Get a single player by ID
  getPlayer: async (id: string): Promise<Player | null> => {
    await delay(300);
    const player = mockPlayers.find(p => p.id === id);
    return player || null;
  },

  // Create a new player
  createPlayer: async (data: PlayerFormData): Promise<Player> => {
    await delay(500);
    
    // Simulate geocoding the address
    const coordinates = await mockGeocode(
      `${data.address.street}, ${data.address.city}, ${data.address.state} ${data.address.zipCode}`
    );

    const newPlayer: Player = {
      id: String(mockPlayers.length + 1),
      ...data,
      address: {
        ...data.address,
        coordinates
      },
      eloRating: 1500,
      totalPoints: 0,
      joinDate: new Date().toISOString().split('T')[0],
      matchesPlayed: 0,
      wins: 0,
      losses: 0,
      profileImage: `https://ui-avatars.com/api/?name=${encodeURIComponent(data.name)}&background=0D9488&color=fff`
    };
    mockPlayers.push(newPlayer);
    return newPlayer;
  },

  // Update an existing player
  updatePlayer: async (id: string, data: PlayerFormData): Promise<Player> => {
    await delay(500);
    const index = mockPlayers.findIndex(p => p.id === id);
    if (index === -1) throw new Error('Player not found');

    // Simulate geocoding if address changed
    const coordinates = await mockGeocode(
      `${data.address.street}, ${data.address.city}, ${data.address.state} ${data.address.zipCode}`
    );

    const updatedPlayer = {
      ...mockPlayers[index],
      ...data,
      address: {
        ...data.address,
        coordinates
      }
    };
    mockPlayers[index] = updatedPlayer;
    return updatedPlayer;
  },

  // Delete a player
  deletePlayer: async (id: string): Promise<void> => {
    await delay(500);
    const index = mockPlayers.findIndex(p => p.id === id);
    if (index === -1) throw new Error('Player not found');
    mockPlayers.splice(index, 1);
  }
};
