import React, { useState } from 'react';
import { MatchType, CreateMatchDTO } from '../../types/match';
import { matchService } from '../../services/matchService';
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';

interface MatchFormProps {
  onMatchCreated: () => void;
  onClose: () => void;
}

export const MatchForm: React.FC<MatchFormProps> = ({ onMatchCreated, onClose }) => {
  const [matchType, setMatchType] = useState<MatchType>('singles');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [court, setCourt] = useState('');
  const [address, setAddress] = useState('');
  const [player1Id, setPlayer1Id] = useState('');
  const [player2Id, setPlayer2Id] = useState('');
  const [team1Id, setTeam1Id] = useState('');
  const [team2Id, setTeam2Id] = useState('');
  const [error, setError] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);

  // Generate time slots from 6 AM to 10 PM in 30-minute intervals
  const timeSlots = Array.from({ length: 32 }, (_, i) => {
    const hour = Math.floor(i / 2) + 6;
    const minute = (i % 2) * 30;
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour > 12 ? hour - 12 : hour;
    return {
      label: `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`,
      value: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    };
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!date || !time || !court || !address) {
      setError('Please fill in all required fields');
      return;
    }

    if (matchType === 'singles' && (!player1Id || !player2Id)) {
      setError('Please select both players');
      return;
    }

    if (matchType === 'doubles' && (!team1Id || !team2Id)) {
      setError('Please select both teams');
      return;
    }

    const startTime = `${date}T${time}:00`;

    const matchData: CreateMatchDTO = {
      type: matchType,
      startTime,
      court,
      address,
      ...(matchType === 'singles' 
        ? { player1Id, player2Id }
        : { team1Id, team2Id }
      ),
    };

    try {
      await matchService.createMatch(matchData);
      onMatchCreated();
      onClose();
    } catch (err) {
      setError('Failed to create match');
    }
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-2xl mx-4 overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-dark-700">
          <h2 className="text-xl font-semibold text-gray-100">Create New Match</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-300 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-3">Match Type</label>
              <div className="grid grid-cols-2 gap-4">
                <button
                  type="button"
                  className={`p-4 rounded-lg border-2 transition-all ${
                    matchType === 'singles'
                      ? 'border-primary-500 bg-primary-500/10 text-primary-400'
                      : 'border-dark-700 hover:border-dark-600 text-gray-400'
                  }`}
                  onClick={() => setMatchType('singles')}
                >
                  Singles Match
                </button>
                <button
                  type="button"
                  className={`p-4 rounded-lg border-2 transition-all ${
                    matchType === 'doubles'
                      ? 'border-primary-500 bg-primary-500/10 text-primary-400'
                      : 'border-dark-700 hover:border-dark-600 text-gray-400'
                  }`}
                  onClick={() => setMatchType('doubles')}
                >
                  Doubles Match
                </button>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-300 mb-2">Date</label>
                <div className="relative">
                  <input
                    type="date"
                    min={today}
                    className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500 pl-10"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                  <CalendarIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">Time</label>
                <div className="relative">
                  <select
                    className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500 pl-10"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    required
                  >
                    <option value="">Select time</option>
                    {timeSlots.map((slot) => (
                      <option key={slot.value} value={slot.value}>
                        {slot.label}
                      </option>
                    ))}
                  </select>
                  <ClockIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div>
            </div>

            {matchType === 'singles' ? (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">Player 1</label>
                  <select
                    className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    value={player1Id}
                    onChange={(e) => setPlayer1Id(e.target.value)}
                    required
                  >
                    <option value="">Select Player 1</option>
                    <option value="1">John Smith</option>
                    <option value="2">Sarah Johnson</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">Player 2</label>
                  <select
                    className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    value={player2Id}
                    onChange={(e) => setPlayer2Id(e.target.value)}
                    required
                  >
                    <option value="">Select Player 2</option>
                    <option value="3">Mike Wilson</option>
                    <option value="4">Tom Brown</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">Team 1</label>
                  <select
                    className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    value={team1Id}
                    onChange={(e) => setTeam1Id(e.target.value)}
                    required
                  >
                    <option value="">Select Team 1</option>
                    <option value="1">Team Alpha</option>
                    <option value="2">Team Beta</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">Team 2</label>
                  <select
                    className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    value={team2Id}
                    onChange={(e) => setTeam2Id(e.target.value)}
                    required
                  >
                    <option value="">Select Team 2</option>
                    <option value="3">Team Gamma</option>
                    <option value="4">Team Delta</option>
                  </select>
                </div>
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">Court</label>
              <input
                type="text"
                className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500"
                value={court}
                onChange={(e) => setCourt(e.target.value)}
                placeholder="Enter court number or name"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">Address</label>
              <input
                type="text"
                className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter match location address"
                required
              />
            </div>
          </div>

          {error && (
            <div className="text-red-500 text-sm mt-2">{error}</div>
          )}

          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded-lg border border-dark-600 text-gray-300 hover:bg-dark-700 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 rounded-lg bg-primary-500 text-white hover:bg-primary-600 transition-colors"
            >
              Create Match
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
