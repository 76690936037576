import React from 'react';
import { Court } from '../../types/court';
import { MapPinIcon, ClockIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

interface CourtCardProps {
  court: Court;
  onEdit: (court: Court) => void;
  onDelete: (court: Court) => void;
}

const formatTimeSlots = (slots: { start: string; end: string }[]) => {
  return slots.map(slot => `${slot.start} - ${slot.end}`).join(', ');
};

export const CourtCard: React.FC<CourtCardProps> = ({ court, onEdit, onDelete }) => {
  const today = new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase() as keyof Court['availability'];
  const todaySlots = court.availability[today];

  return (
    <div className="card p-6 hover:border-primary-500/50 transition-colors">
      <div className="flex items-start justify-between mb-6">
        <div>
          <h3 className="text-xl font-semibold text-gray-100">{court.name}</h3>
          <div className="flex items-center mt-2 text-gray-400">
            <MapPinIcon className="w-4 h-4 mr-2" />
            <p className="text-sm">
              {court.address}, {court.city}, {court.state} {court.zipCode}
            </p>
          </div>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => onEdit(court)}
            className="p-2 rounded-lg hover:bg-dark-700 transition-colors"
          >
            <PencilIcon className="w-5 h-5 text-gray-400" />
          </button>
          <button
            onClick={() => onDelete(court)}
            className="p-2 rounded-lg hover:bg-dark-700 transition-colors"
          >
            <TrashIcon className="w-5 h-5 text-red-400" />
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <div className="p-4 rounded-lg bg-dark-700">
          <div className="flex items-center mb-3">
            <ClockIcon className="w-5 h-5 text-primary-400 mr-2" />
            <h4 className="text-sm font-medium text-gray-300">Today's Availability</h4>
          </div>
          {todaySlots.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {todaySlots.map((slot, index) => (
                <span
                  key={index}
                  className="px-3 py-1 text-sm rounded-full bg-primary-500/10 text-primary-400"
                >
                  {slot.start} - {slot.end}
                </span>
              ))}
            </div>
          ) : (
            <p className="text-sm text-gray-500">No availability today</p>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          {Object.entries(court.availability).map(([day, slots]) => (
            day !== today && (
              <div key={day} className="p-3 rounded-lg bg-dark-800">
                <h5 className="text-sm font-medium text-gray-400 capitalize mb-2">
                  {day}
                </h5>
                <p className="text-sm text-gray-500">
                  {slots.length > 0
                    ? formatTimeSlots(slots)
                    : 'Closed'}
                </p>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};
