import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { Match } from '../../types/match';
import { CalendarIcon, ClockIcon, UserGroupIcon, TrophyIcon } from '@heroicons/react/24/outline';

interface MatchMapProps {
  matches: Match[];
  onEditOutcome: (match: Match) => void;
}

interface Location {
  lat: number;
  lng: number;
}

interface MatchWithLocation extends Match {
  location?: Location;
}

const DEFAULT_CENTER: Location = { lat: 40.7128, lng: -74.0060 }; // NYC

const customIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const MatchMap: React.FC<MatchMapProps> = ({ matches, onEditOutcome }) => {
  const [matchesWithLocations, setMatchesWithLocations] = useState<MatchWithLocation[]>([]);
  const [loading, setLoading] = useState(true);

  const geocodeAddress = async (address: string): Promise<Location | null> => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`
      );
      const data = await response.json();
      if (data && data[0]) {
        return {
          lat: parseFloat(data[0].lat),
          lng: parseFloat(data[0].lon)
        };
      }
      return null;
    } catch (error) {
      console.error('Geocoding error:', error);
      return null;
    }
  };

  useEffect(() => {
    const getLocations = async () => {
      const matchPromises = matches.map(async (match) => {
        const location = await geocodeAddress(match.address);
        return { ...match, location: location || undefined };
      });

      const matchesWithLocs = await Promise.all(matchPromises);
      setMatchesWithLocations(matchesWithLocs.filter(m => m.location !== undefined));
      setLoading(false);
    };

    getLocations();
  }, [matches]);

  const formatDate = (dateStr: string) => {
    return new Date(dateStr).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTime = (dateStr: string) => {
    return new Date(dateStr).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const getWinnerName = (match: Match) => {
    if (!match.outcome) return null;
    
    if (match.type === 'singles') {
      return match.outcome.winner === 'player1' ? match.player1?.name : match.player2?.name;
    } else {
      return match.outcome.winner === 'team1' 
        ? match.team1?.players.map(p => p.name).join(', ')
        : match.team2?.players.map(p => p.name).join(', ');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[600px] bg-dark-800 rounded-lg">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  const mapCenter = matchesWithLocations.length > 0 && matchesWithLocations[0].location
    ? matchesWithLocations[0].location
    : DEFAULT_CENTER;

  return (
    <MapContainer
      center={[mapCenter.lat, mapCenter.lng]}
      zoom={12}
      className="h-[600px] rounded-lg"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {matchesWithLocations.map((match) => (
        match.location && (
          <Marker
            key={match.id}
            position={[match.location.lat, match.location.lng]}
            icon={customIcon}
          >
            <Popup className="match-popup">
              <div className="p-2 min-w-[300px]">
                <div className="flex items-center justify-between mb-3">
                  <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                    match.type === 'singles'
                      ? 'bg-blue-500/20 text-blue-400'
                      : 'bg-purple-500/20 text-purple-400'
                  }`}>
                    {match.type === 'singles' ? 'Singles Match' : 'Doubles Match'}
                  </span>
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    match.status === 'upcoming'
                      ? 'bg-green-500/20 text-green-400'
                      : match.status === 'in_progress'
                      ? 'bg-yellow-500/20 text-yellow-400'
                      : 'bg-gray-500/20 text-gray-400'
                  }`}>
                    {match.status.charAt(0).toUpperCase() + match.status.slice(1)}
                  </span>
                </div>

                <div className="space-y-3">
                  <div className="flex items-center space-x-2">
                    <CalendarIcon className="w-4 h-4 text-gray-400" />
                    <span className="text-gray-300">{formatDate(match.startTime)}</span>
                    <ClockIcon className="w-4 h-4 text-gray-400 ml-2" />
                    <span className="text-gray-300">{formatTime(match.startTime)}</span>
                  </div>

                  <div className="flex items-center space-x-2">
                    <UserGroupIcon className="w-4 h-4 text-gray-400" />
                    <span className="text-gray-300">
                      {match.type === 'singles'
                        ? `${match.player1?.name} vs ${match.player2?.name}`
                        : `${match.team1?.players.map(p => p.name).join(', ')} vs ${match.team2?.players.map(p => p.name).join(', ')}`}
                    </span>
                  </div>

                  <div className="text-gray-300">
                    <strong className="text-gray-200">Court:</strong> {match.court}
                  </div>

                  {match.status === 'completed' && match.outcome && (
                    <div className="flex items-center space-x-2 mt-2">
                      <TrophyIcon className="w-4 h-4 text-yellow-400" />
                      <span className="text-primary-400">{getWinnerName(match)}</span>
                      <span className="text-gray-500">({match.outcome.score})</span>
                    </div>
                  )}

                  <button
                    onClick={() => onEditOutcome(match)}
                    className="btn-primary btn-sm w-full mt-2"
                  >
                    {match.outcome ? 'Edit Outcome' : 'Add Outcome'}
                  </button>
                </div>
              </div>
            </Popup>
          </Marker>
        )
      ))}
    </MapContainer>
  );
};
