import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Match, MatchStatus } from '../types/match';
import { matchService } from '../services/matchService';
import { MatchForm } from '../components/matches/MatchForm';
import { MatchOutcomeForm } from '../components/matches/MatchOutcomeForm';
import { MatchCard } from '../components/matches/MatchCard';
import { MatchMap } from '../components/matches/MatchMap';
import { 
  Squares2X2Icon, 
  ViewColumnsIcon, 
  ListBulletIcon,
  MapIcon,
  CalendarIcon,
  MapPinIcon,
  UserGroupIcon,
  ClockIcon,
  TrophyIcon
} from '@heroicons/react/24/outline';

type ViewType = 'grid' | 'list' | 'kanban' | 'map';

const MATCH_STATUSES: { id: MatchStatus; label: string }[] = [
  { id: 'upcoming', label: 'Upcoming' },
  { id: 'in_progress', label: 'In Progress' },
  { id: 'completed', label: 'Completed' }
];

const Matches: React.FC = () => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
  const [viewType, setViewType] = useState<ViewType>('kanban');

  const fetchMatches = async () => {
    try {
      const fetchedMatches = await matchService.getMatches();
      setMatches(fetchedMatches);
    } catch (error) {
      console.error('Failed to fetch matches:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  const handleMatchCreated = () => {
    fetchMatches();
  };

  const handleDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    try {
      const newStatus = destination.droppableId as MatchStatus;
      await matchService.updateMatchStatus(draggableId, { status: newStatus });
      await fetchMatches();
    } catch (error) {
      console.error('Failed to update match status:', error);
    }
  };

  const getMatchesByStatus = (status: MatchStatus) => {
    return matches.filter(match => match.status === status);
  };

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }).format(date);
  };

  const formatTime = (dateStr: string) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };

  const getWinnerName = (match: Match) => {
    if (!match.outcome) return null;
    
    if (match.type === 'singles') {
      return match.outcome.winner === 'player1' ? match.player1?.name : match.player2?.name;
    } else {
      return match.outcome.winner === 'team1' 
        ? match.team1?.players.map(p => p.name).join(', ')
        : match.team2?.players.map(p => p.name).join(', ');
    }
  };

  if (loading) {
    return (
      <div className="card p-8">
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500"></div>
        </div>
      </div>
    );
  }

  const renderListView = () => (
    <div className="space-y-4">
      {matches.map((match) => (
        <div 
          key={match.id} 
          className="card p-4 hover:border-primary-500/50 transition-colors cursor-pointer"
          onClick={() => setSelectedMatch(match)}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                  match.type === 'singles'
                    ? 'bg-blue-500/20 text-blue-400'
                    : 'bg-purple-500/20 text-purple-400'
                }`}>
                  {match.type === 'singles' ? 'Singles Match' : 'Doubles Match'}
                </span>
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                  match.status === 'upcoming'
                    ? 'bg-green-500/20 text-green-400'
                    : match.status === 'in_progress'
                    ? 'bg-yellow-500/20 text-yellow-400'
                    : 'bg-gray-500/20 text-gray-400'
                }`}>
                  {MATCH_STATUSES.find(s => s.id === match.status)?.label}
                </span>
              </div>
              <div className="flex items-center space-x-2 text-gray-300">
                <CalendarIcon className="w-4 h-4 text-gray-400" />
                <span>{formatDate(match.startTime)}</span>
                <ClockIcon className="w-4 h-4 text-gray-400 ml-2" />
                <span>{formatTime(match.startTime)}</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-300">
                <MapPinIcon className="w-4 h-4 text-gray-400" />
                <span>{match.court}</span>
              </div>
            </div>
            {match.status === 'completed' && match.outcome && (
              <div className="flex items-center space-x-2">
                <TrophyIcon className="w-4 h-4 text-yellow-400" />
                <span className="text-primary-400">{getWinnerName(match)}</span>
                <span className="text-gray-500 text-sm">({match.outcome.score})</span>
              </div>
            )}
          </div>
          <div className="mt-3 flex items-center space-x-2 text-gray-400">
            <UserGroupIcon className="w-4 h-4" />
            <span>
              {match.type === 'singles'
                ? `${match.player1?.name} vs ${match.player2?.name}`
                : `${match.team1?.players.map(p => p.name).join(', ')} vs ${match.team2?.players.map(p => p.name).join(', ')}`}
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="space-y-6 animate-fadeInUp">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-semibold text-gray-100">Matches</h1>
          <div className="flex items-center bg-dark-800 rounded-lg p-1">
            <button
              onClick={() => setViewType('grid')}
              className={`p-2 rounded-md transition-colors ${
                viewType === 'grid'
                  ? 'bg-dark-700 text-primary-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              title="Grid View"
            >
              <Squares2X2Icon className="w-5 h-5" />
            </button>
            <button
              onClick={() => setViewType('list')}
              className={`p-2 rounded-md transition-colors ${
                viewType === 'list'
                  ? 'bg-dark-700 text-primary-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              title="List View"
            >
              <ListBulletIcon className="w-5 h-5" />
            </button>
            <button
              onClick={() => setViewType('kanban')}
              className={`p-2 rounded-md transition-colors ${
                viewType === 'kanban'
                  ? 'bg-dark-700 text-primary-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              title="Kanban View"
            >
              <ViewColumnsIcon className="w-5 h-5" />
            </button>
            <button
              onClick={() => setViewType('map')}
              className={`p-2 rounded-md transition-colors ${
                viewType === 'map'
                  ? 'bg-dark-700 text-primary-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              title="Map View"
            >
              <MapIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="btn-primary flex items-center space-x-2"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          <span>Create Match</span>
        </button>
      </div>

      {matches.length === 0 ? (
        <div className="card p-8">
          <div className="text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
            </svg>
            <h3 className="mt-2 text-lg font-medium text-gray-300">No matches scheduled</h3>
            <p className="mt-1 text-gray-500">Get started by creating a new match.</p>
            <button
              onClick={() => setShowForm(true)}
              className="mt-4 btn-primary"
            >
              Create Match
            </button>
          </div>
        </div>
      ) : viewType === 'grid' ? (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {matches.map((match) => (
            <MatchCard
              key={match.id}
              match={match}
              onEditOutcome={setSelectedMatch}
            />
          ))}
        </div>
      ) : viewType === 'list' ? (
        renderListView()
      ) : viewType === 'map' ? (
        <MatchMap
          matches={matches}
          onEditOutcome={setSelectedMatch}
        />
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="grid grid-cols-3 gap-6">
            {MATCH_STATUSES.map((status) => (
              <div key={status.id} className="space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium text-gray-300">{status.label}</h3>
                  <span className="px-2 py-1 rounded-full text-xs font-medium bg-dark-700 text-gray-400">
                    {getMatchesByStatus(status.id).length}
                  </span>
                </div>
                <Droppable droppableId={status.id}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`space-y-4 min-h-[200px] rounded-lg p-4 ${
                        snapshot.isDraggingOver ? 'bg-dark-700/50' : 'bg-dark-800/30'
                      }`}
                    >
                      {getMatchesByStatus(status.id).map((match, index) => (
                        <Draggable
                          key={match.id}
                          draggableId={match.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={snapshot.isDragging ? 'opacity-50' : ''}
                            >
                              <MatchCard
                                match={match}
                                onEditOutcome={setSelectedMatch}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>
      )}

      {showForm && (
        <MatchForm
          onMatchCreated={handleMatchCreated}
          onClose={() => setShowForm(false)}
        />
      )}

      {selectedMatch && (
        <MatchOutcomeForm
          match={selectedMatch}
          onOutcomeUpdated={() => {
            fetchMatches();
            setSelectedMatch(null);
          }}
          onClose={() => setSelectedMatch(null)}
        />
      )}
    </div>
  );
};

export default Matches;
