import React, { useState } from 'react';
import { Match, UpdateMatchOutcomeDTO } from '../../types/match';
import { matchService } from '../../services/matchService';

interface MatchOutcomeFormProps {
  match: Match;
  onOutcomeUpdated: () => void;
  onClose: () => void;
}

export const MatchOutcomeForm: React.FC<MatchOutcomeFormProps> = ({
  match,
  onOutcomeUpdated,
  onClose,
}) => {
  const [winner, setWinner] = useState<UpdateMatchOutcomeDTO['winner']>(
    match.outcome?.winner || (match.type === 'singles' ? 'player1' : 'team1')
  );
  const [score, setScore] = useState(match.outcome?.score || '');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!score) {
      setError('Please enter the match score');
      return;
    }

    try {
      await matchService.updateMatchOutcome(match.id, { winner, score });
      onOutcomeUpdated();
      onClose();
    } catch (err) {
      setError('Failed to update match outcome');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-md mx-4">
        <div className="flex items-center justify-between p-6 border-b border-dark-700">
          <h2 className="text-xl font-semibold text-gray-100">Update Match Outcome</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-300 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-3">Winner</label>
            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                className={`p-4 rounded-lg border-2 transition-all ${
                  winner === (match.type === 'singles' ? 'player1' : 'team1')
                    ? 'border-primary-500 bg-primary-500/10 text-primary-400'
                    : 'border-dark-700 hover:border-dark-600 text-gray-400'
                }`}
                onClick={() => setWinner(match.type === 'singles' ? 'player1' : 'team1')}
              >
                {match.type === 'singles' 
                  ? match.player1?.name
                  : match.team1?.players.map(p => p.name).join(', ')}
              </button>
              <button
                type="button"
                className={`p-4 rounded-lg border-2 transition-all ${
                  winner === (match.type === 'singles' ? 'player2' : 'team2')
                    ? 'border-primary-500 bg-primary-500/10 text-primary-400'
                    : 'border-dark-700 hover:border-dark-600 text-gray-400'
                }`}
                onClick={() => setWinner(match.type === 'singles' ? 'player2' : 'team2')}
              >
                {match.type === 'singles'
                  ? match.player2?.name
                  : match.team2?.players.map(p => p.name).join(', ')}
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">Score</label>
            <input
              type="text"
              className="w-full bg-dark-700 border-dark-600 rounded-lg text-gray-300 focus:ring-primary-500 focus:border-primary-500"
              value={score}
              onChange={(e) => setScore(e.target.value)}
              placeholder="e.g. 21-18, 21-19"
              required
            />
            <p className="mt-2 text-sm text-gray-500">Enter the score in format: 21-18, 21-19</p>
          </div>

          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded-lg border border-dark-600 text-gray-300 hover:bg-dark-700 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 rounded-lg bg-primary-500 text-white hover:bg-primary-600 transition-colors"
            >
              Save Outcome
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
