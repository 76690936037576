import { useState, useEffect } from 'react';
import { Player, PlayerFormData } from '../../types/player';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface PlayerFormProps {
  player?: Player;
  onSubmit: (data: PlayerFormData) => Promise<void>;
  onClose: () => void;
}

export default function PlayerForm({ player, onSubmit, onClose }: PlayerFormProps) {
  const [formData, setFormData] = useState<PlayerFormData>({
    name: '',
    email: '',
    phone: '',
    status: 'active',
    currentTeam: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: ''
    }
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (player) {
      setFormData({
        name: player.name,
        email: player.email,
        phone: player.phone,
        status: player.status,
        currentTeam: player.currentTeam || '',
        address: {
          street: player.address.street,
          city: player.address.city,
          state: player.address.state,
          zipCode: player.address.zipCode
        }
      });
    }
  }, [player]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await onSubmit(formData);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  return (
    <div className="fixed inset-0 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-md p-6 relative animate-fadeInUp">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-300 transition-colors"
        >
          <XMarkIcon className="w-6 h-6" />
        </button>

        <h2 className="text-xl font-semibold text-gray-100 mb-6">
          {player ? 'Edit Player' : 'Add New Player'}
        </h2>

        {error && (
          <div className="mb-4 p-3 rounded-lg bg-red-500/10 text-red-400 text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="input"
              placeholder="Enter player name"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input"
              placeholder="Enter email address"
              required
            />
          </div>

          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-1">
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="input"
              placeholder="Enter phone number"
              required
            />
          </div>

          <div className="border-t border-dark-700 pt-4">
            <h3 className="text-sm font-medium text-gray-300 mb-3">Address</h3>
            <div className="space-y-3">
              <div>
                <label htmlFor="address.street" className="block text-sm font-medium text-gray-300 mb-1">
                  Street Address
                </label>
                <input
                  type="text"
                  id="address.street"
                  name="address.street"
                  value={formData.address.street}
                  onChange={handleChange}
                  className="input"
                  placeholder="Enter street address"
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label htmlFor="address.city" className="block text-sm font-medium text-gray-300 mb-1">
                    City
                  </label>
                  <input
                    type="text"
                    id="address.city"
                    name="address.city"
                    value={formData.address.city}
                    onChange={handleChange}
                    className="input"
                    placeholder="Enter city"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="address.state" className="block text-sm font-medium text-gray-300 mb-1">
                    State
                  </label>
                  <input
                    type="text"
                    id="address.state"
                    name="address.state"
                    value={formData.address.state}
                    onChange={handleChange}
                    className="input"
                    placeholder="Enter state"
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="address.zipCode" className="block text-sm font-medium text-gray-300 mb-1">
                  ZIP Code
                </label>
                <input
                  type="text"
                  id="address.zipCode"
                  name="address.zipCode"
                  value={formData.address.zipCode}
                  onChange={handleChange}
                  className="input"
                  placeholder="Enter ZIP code"
                  required
                />
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-300 mb-1">
              Status
            </label>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-dark-700 border border-dark-600 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          <div>
            <label htmlFor="currentTeam" className="block text-sm font-medium text-gray-300 mb-1">
              Current Team
            </label>
            <input
              type="text"
              id="currentTeam"
              name="currentTeam"
              value={formData.currentTeam}
              onChange={handleChange}
              className="input"
              placeholder="Enter team name (optional)"
            />
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="btn-secondary"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-primary relative"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="opacity-0">Save</span>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-r-2 border-white rounded-full animate-spin"></div>
                  </div>
                </>
              ) : (
                player ? 'Save Changes' : 'Add Player'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
