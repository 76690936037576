import React, { useState, useEffect } from 'react';
import { Team, TeamFormData } from '../../types/team';
import { Player } from '../../types/player';
import { playerService } from '../../services/playerService';
import { XMarkIcon, PlusIcon, UserMinusIcon } from '@heroicons/react/24/outline';

interface TeamFormProps {
  onSubmit: (data: TeamFormData) => Promise<void>;
  initialData?: Team;
  onCancel: () => void;
}

export const TeamForm: React.FC<TeamFormProps> = ({
  onSubmit,
  initialData,
  onCancel
}) => {
  const [formData, setFormData] = useState<TeamFormData>({
    name: '',
    description: '',
    captainId: '',
    division: '',
    members: [],
    ...initialData
  });

  const [availablePlayers, setAvailablePlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('');

  useEffect(() => {
    const loadPlayers = async () => {
      try {
        const players = await playerService.getPlayers({ status: 'active' });
        setAvailablePlayers(players);
      } catch (err) {
        setError('Failed to load players');
      }
    };
    loadPlayers();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await onSubmit(formData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddPlayer = () => {
    if (selectedPlayerId && !formData.members.includes(selectedPlayerId)) {
      setFormData(prev => ({
        ...prev,
        members: [...prev.members, selectedPlayerId]
      }));
      setSelectedPlayerId('');
    }
  };

  const handleRemovePlayer = (playerId: string) => {
    setFormData(prev => ({
      ...prev,
      members: prev.members.filter(id => id !== playerId)
    }));
  };

  const getPlayerName = (playerId: string) => {
    const player = availablePlayers.find(p => p.id === playerId);
    return player ? player.name : 'Unknown Player';
  };

  const nonMemberPlayers = availablePlayers.filter(
    player => !formData.members.includes(player.id) && player.id !== formData.captainId
  );

  return (
    <div className="fixed inset-0 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-md p-6 relative animate-fadeInUp">
        <button
          onClick={onCancel}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-300 transition-colors"
        >
          <XMarkIcon className="w-6 h-6" />
        </button>

        <h2 className="text-xl font-semibold text-gray-100 mb-6">
          {initialData ? 'Edit Team' : 'Add New Team'}
        </h2>

        {error && (
          <div className="mb-4 p-3 rounded-lg bg-red-500/10 text-red-400 text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
              Team Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
              className="input"
              placeholder="Enter team name"
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-300 mb-1">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description || ''}
              onChange={handleChange}
              rows={3}
              className="input"
              placeholder="Enter team description"
            />
          </div>

          <div>
            <label htmlFor="captainId" className="block text-sm font-medium text-gray-300 mb-1">
              Team Captain
            </label>
            <select
              id="captainId"
              name="captainId"
              required
              value={formData.captainId}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-dark-700 border border-dark-600 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
            >
              <option value="">Select a captain</option>
              {availablePlayers.map(player => (
                <option key={player.id} value={player.id}>
                  {player.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="division" className="block text-sm font-medium text-gray-300 mb-1">
              Division
            </label>
            <select
              id="division"
              name="division"
              value={formData.division || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-dark-700 border border-dark-600 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
            >
              <option value="">Select a division</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
              <option value="Elite">Elite</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Team Members
            </label>
            <div className="flex space-x-2 mb-2">
              <select
                value={selectedPlayerId}
                onChange={(e) => setSelectedPlayerId(e.target.value)}
                className="flex-1 px-3 py-2 bg-dark-700 border border-dark-600 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
              >
                <option value="">Select a player</option>
                {nonMemberPlayers.map(player => (
                  <option key={player.id} value={player.id}>
                    {player.name}
                  </option>
                ))}
              </select>
              <button
                type="button"
                onClick={handleAddPlayer}
                disabled={!selectedPlayerId}
                className="px-3 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <PlusIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-2">
              {formData.members.map(memberId => (
                <div key={memberId} className="flex items-center justify-between p-2 bg-dark-700 rounded-lg">
                  <span className="text-gray-200">{getPlayerName(memberId)}</span>
                  <button
                    type="button"
                    onClick={() => handleRemovePlayer(memberId)}
                    className="text-gray-400 hover:text-red-400"
                  >
                    <UserMinusIcon className="w-5 h-5" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onCancel}
              className="btn-secondary"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-primary relative"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="opacity-0">Save</span>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-r-2 border-white rounded-full animate-spin"></div>
                  </div>
                </>
              ) : (
                initialData ? 'Save Changes' : 'Add Team'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
