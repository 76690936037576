import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  UsersIcon,
  UserGroupIcon,
  CalendarIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Players', href: '/players', icon: UsersIcon },
  { name: 'Teams', href: '/teams', icon: UserGroupIcon },
  { name: 'Matches', href: '/matches', icon: CalendarIcon },
  { name: 'Courts', href: '/courts', icon: MapPinIcon },
];

export default function Sidebar() {
  const location = useLocation();

  return (
    <div className="flex flex-col h-full bg-dark-800 border-r border-dark-700">
      <div className="flex items-center h-16 px-4 border-b border-dark-700">
        <h1 className="text-xl font-bold text-gray-100">Pickleball Admin</h1>
      </div>
      <nav className="flex-1 p-4 space-y-1">
        {navigation.map((item) => {
          const isActive = location.pathname === item.href;
          return (
            <Link
              key={item.name}
              to={item.href}
              className={`flex items-center px-3 py-2 rounded-lg transition-colors ${
                isActive
                  ? 'bg-primary-500/10 text-primary-400'
                  : 'text-gray-400 hover:bg-dark-700 hover:text-gray-300'
              }`}
            >
              <item.icon className="w-5 h-5 mr-3" />
              {item.name}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}
