import {
  UsersIcon,
  UserGroupIcon,
  TrophyIcon,
  CalendarIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  MinusIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { MatchModal } from '../components/matches/MatchModal';
import { Match } from '../types/match';

const stats = [
  {
    name: 'Total Players',
    value: '2,847',
    icon: UsersIcon,
    change: '+12%',
    changeType: 'positive',
  },
  {
    name: 'Active Teams',
    value: '89',
    icon: UserGroupIcon,
    change: '+4%',
    changeType: 'positive',
  },
  {
    name: 'Tournaments',
    value: '12',
    icon: TrophyIcon,
    change: '0',
    changeType: 'neutral',
  },
  {
    name: 'Upcoming Matches',
    value: '156',
    icon: CalendarIcon,
    change: '+8%',
    changeType: 'positive',
  },
];

const upcomingMatches: Match[] = [
  {
    id: '1',
    type: 'singles',
    status: 'upcoming',
    startTime: '2024-02-15T14:00:00',
    court: 'Court A',
    address: '123 Sports Center',
    player1: { id: '1', name: 'John Smith' },
    player2: { id: '2', name: 'Mike Johnson' },
    createdAt: '2024-02-01T00:00:00',
    updatedAt: '2024-02-01T00:00:00'
  },
  {
    id: '2',
    type: 'doubles',
    status: 'upcoming',
    startTime: '2024-02-15T15:30:00',
    court: 'Court B',
    address: '123 Sports Center',
    team1: {
      id: '1',
      players: [
        { id: '3', name: 'Sarah Davis' },
        { id: '4', name: 'Emma Wilson' }
      ]
    },
    team2: {
      id: '2',
      players: [
        { id: '5', name: 'Tom Brown' },
        { id: '6', name: 'James Miller' }
      ]
    },
    createdAt: '2024-02-01T00:00:00',
    updatedAt: '2024-02-01T00:00:00'
  }
];

const getChangeIcon = (changeType: string) => {
  switch (changeType) {
    case 'positive':
      return <ArrowUpIcon className="w-4 h-4 text-emerald-400" />;
    case 'negative':
      return <ArrowDownIcon className="w-4 h-4 text-red-400" />;
    default:
      return <MinusIcon className="w-4 h-4 text-gray-400" />;
  }
};

const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
  const day = date.getDate();
  const year = date.getFullYear();
  return { month, day, year };
};

export default function Dashboard() {
  const navigate = useNavigate();
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between animate-fadeInUp">
        <h1 className="text-2xl font-semibold text-gray-100">Dashboard</h1>
        <button className="btn-primary">
          Generate Report
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat, index) => (
          <div 
            key={stat.name} 
            className={`card p-5 hover:border-primary-500/50 transition-colors duration-300 animate-fadeInUp-delay-${index}`}
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 p-3 rounded-lg bg-[#1A2234]">
                <stat.icon className="h-6 w-6 text-primary-400" />
              </div>
              <div className="ml-4 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-400 truncate">
                    {stat.name}
                  </dt>
                  <dd className="flex items-baseline">
                    <div className="text-2xl font-semibold text-gray-100">
                      {stat.value}
                    </div>
                    {stat.change !== '0' && (
                      <div className="ml-2 flex items-center text-sm font-medium">
                        {getChangeIcon(stat.changeType)}
                        <span className={`ml-1 ${
                          stat.changeType === 'positive' ? 'text-emerald-400' :
                          stat.changeType === 'negative' ? 'text-red-400' :
                          'text-gray-400'
                        }`}>
                          {stat.change}
                        </span>
                      </div>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="card p-6 animate-fadeInUp-delay-3">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-medium text-gray-100">Upcoming Matches</h2>
          <button 
            className="btn-secondary"
            onClick={() => navigate('/matches')}
          >
            View All
          </button>
        </div>
        <div className="space-y-4">
          {upcomingMatches.map((match) => {
            const { month, day, year } = formatDate(match.startTime);
            return (
              <div
                key={match.id}
                onClick={() => setSelectedMatch(match)}
                className="p-4 rounded-lg bg-dark-800 hover:bg-dark-700 transition-colors cursor-pointer"
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-4">
                    <div className="text-center">
                      <div className="text-xs font-bold tracking-wider text-primary-400">{month}</div>
                      <div className="text-2xl font-bold text-gray-100 -mt-1">{day}</div>
                      <div className="text-xs font-medium text-gray-500 -mt-1">{year}</div>
                    </div>
                  </div>
                  <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    match.type === 'singles'
                      ? 'bg-blue-500/20 text-blue-400'
                      : 'bg-purple-500/20 text-purple-400'
                  }`}>
                    {match.type === 'singles' ? 'Singles' : 'Doubles'}
                  </span>
                </div>
                
                <div className="flex items-center justify-between">
                  <div className="space-y-2">
                    {match.type === 'singles' ? (
                      <>
                        <p className="text-gray-200 font-medium">{match.player1?.name}</p>
                        <p className="text-gray-200 font-medium">{match.player2?.name}</p>
                      </>
                    ) : (
                      <>
                        <p className="text-gray-200 font-medium">
                          {match.team1?.players.map(p => p.name).join(' & ')}
                        </p>
                        <p className="text-gray-200 font-medium">
                          {match.team2?.players.map(p => p.name).join(' & ')}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="text-sm text-gray-400">
                    {new Date(match.startTime).toLocaleTimeString('en-US', {
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {selectedMatch && (
        <MatchModal
          match={selectedMatch}
          onClose={() => setSelectedMatch(null)}
        />
      )}
    </div>
  );
}
