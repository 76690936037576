import React from 'react';
import { Court } from '../../types/court';
import { MapPinIcon } from '@heroicons/react/24/outline';

interface CourtMapProps {
  courts: Court[];
  onCourtClick?: (court: Court) => void;
}

export const CourtMap: React.FC<CourtMapProps> = ({ courts, onCourtClick }) => {
  return (
    <div className="card p-6 h-full">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-medium text-gray-100">Court Locations</h2>
      </div>
      <div className="space-y-4">
        {courts.map(court => (
          <div
            key={court.id}
            onClick={() => onCourtClick?.(court)}
            className="flex items-start p-4 rounded-lg bg-dark-700 hover:bg-dark-600 transition-colors cursor-pointer"
          >
            <div className="flex-shrink-0 p-2 rounded-lg bg-primary-500/10">
              <MapPinIcon className="w-6 h-6 text-primary-400" />
            </div>
            <div className="ml-4">
              <h3 className="text-gray-200 font-medium">{court.name}</h3>
              <p className="text-gray-400 text-sm mt-1">
                {court.address}, {court.city}, {court.state} {court.zipCode}
              </p>
              <div className="flex flex-wrap gap-2 mt-2">
                {Object.entries(court.availability).map(([day, slots]) => (
                  slots.length > 0 && (
                    <span
                      key={day}
                      className="px-2 py-0.5 text-xs rounded-full bg-dark-800 text-gray-400"
                    >
                      {day.charAt(0).toUpperCase() + day.slice(1)}
                    </span>
                  )
                ))}
              </div>
            </div>
          </div>
        ))}
        {courts.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-400">No courts added yet</p>
          </div>
        )}
      </div>
    </div>
  );
};
