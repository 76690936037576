import { Team, TeamFormData } from '../types/team';

// Mock data
const mockTeams: Team[] = [
  {
    id: '1',
    name: 'Riverside Smashers',
    description: 'A competitive team from the Riverside area',
    captainId: '1', // John Smith
    members: ['1'],
    createdAt: '2023-08-01',
    status: 'active',
    wins: 25,
    losses: 10,
    totalPoints: 2800,
    division: 'Advanced'
  },
  {
    id: '2',
    name: 'Downtown Champions',
    description: 'Downtown\'s finest pickleball team',
    captainId: '2', // Sarah Davis
    members: ['2'],
    createdAt: '2023-06-15',
    status: 'active',
    wins: 32,
    losses: 8,
    totalPoints: 3500,
    division: 'Elite'
  }
];

// Simulate API delay
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const teamService = {
  // Get all teams with optional filtering
  getTeams: async (filters?: {
    status?: 'active' | 'inactive';
    search?: string;
    division?: string;
  }): Promise<Team[]> => {
    await delay(500);

    let filteredTeams = [...mockTeams];

    if (filters) {
      if (filters.status) {
        filteredTeams = filteredTeams.filter(t => t.status === filters.status);
      }
      if (filters.search) {
        const searchLower = filters.search.toLowerCase();
        filteredTeams = filteredTeams.filter(t =>
          t.name.toLowerCase().includes(searchLower) ||
          t.description?.toLowerCase().includes(searchLower)
        );
      }
      if (filters.division) {
        filteredTeams = filteredTeams.filter(t => t.division === filters.division);
      }
    }

    return filteredTeams;
  },

  // Get a single team by ID
  getTeam: async (id: string): Promise<Team | null> => {
    await delay(300);
    const team = mockTeams.find(t => t.id === id);
    return team || null;
  },

  // Create a new team
  createTeam: async (data: TeamFormData): Promise<Team> => {
    await delay(500);
    
    const newTeam: Team = {
      id: String(mockTeams.length + 1),
      ...data,
      members: [data.captainId], // Initially just the captain
      createdAt: new Date().toISOString().split('T')[0],
      status: 'active',
      wins: 0,
      losses: 0,
      totalPoints: 0
    };
    mockTeams.push(newTeam);
    return newTeam;
  },

  // Update an existing team
  updateTeam: async (id: string, data: TeamFormData): Promise<Team> => {
    await delay(500);
    const index = mockTeams.findIndex(t => t.id === id);
    if (index === -1) throw new Error('Team not found');

    const updatedTeam = {
      ...mockTeams[index],
      ...data,
    };
    mockTeams[index] = updatedTeam;
    return updatedTeam;
  },

  // Delete a team
  deleteTeam: async (id: string): Promise<void> => {
    await delay(500);
    const index = mockTeams.findIndex(t => t.id === id);
    if (index === -1) throw new Error('Team not found');
    mockTeams.splice(index, 1);
  },

  // Add a member to a team
  addMember: async (teamId: string, playerId: string): Promise<Team> => {
    await delay(300);
    const team = mockTeams.find(t => t.id === teamId);
    if (!team) throw new Error('Team not found');
    if (team.members.includes(playerId)) throw new Error('Player already in team');
    
    team.members.push(playerId);
    return team;
  },

  // Remove a member from a team
  removeMember: async (teamId: string, playerId: string): Promise<Team> => {
    await delay(300);
    const team = mockTeams.find(t => t.id === teamId);
    if (!team) throw new Error('Team not found');
    if (playerId === team.captainId) throw new Error('Cannot remove team captain');
    
    const memberIndex = team.members.indexOf(playerId);
    if (memberIndex === -1) throw new Error('Player not in team');
    
    team.members.splice(memberIndex, 1);
    return team;
  }
};
