import React, { useState } from 'react';
import { Court, CreateCourtDTO, TimeSlot } from '../../types/court';
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';

interface CourtFormProps {
  court?: Court;
  onSubmit: (data: CreateCourtDTO) => void;
  onCancel: () => void;
}

const emptyTimeSlot: TimeSlot = { start: '09:00', end: '17:00' };
const defaultAvailability = {
  monday: [{ ...emptyTimeSlot }],
  tuesday: [{ ...emptyTimeSlot }],
  wednesday: [{ ...emptyTimeSlot }],
  thursday: [{ ...emptyTimeSlot }],
  friday: [{ ...emptyTimeSlot }],
  saturday: [{ ...emptyTimeSlot }],
  sunday: [{ ...emptyTimeSlot }],
};

export const CourtForm: React.FC<CourtFormProps> = ({ court, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState<CreateCourtDTO>({
    name: court?.name || '',
    address: court?.address || '',
    city: court?.city || '',
    state: court?.state || '',
    zipCode: court?.zipCode || '',
    availability: court?.availability || defaultAvailability,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleTimeSlotChange = (
    day: keyof Court['availability'],
    index: number,
    field: keyof TimeSlot,
    value: string
  ) => {
    setFormData(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: prev.availability[day].map((slot, i) =>
          i === index ? { ...slot, [field]: value } : slot
        ),
      },
    }));
  };

  const addTimeSlot = (day: keyof Court['availability']) => {
    setFormData(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: [...prev.availability[day], { ...emptyTimeSlot }],
      },
    }));
  };

  const removeTimeSlot = (day: keyof Court['availability'], index: number) => {
    setFormData(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: prev.availability[day].filter((_, i) => i !== index),
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Court Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="input-primary w-full"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Address
          </label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className="input-primary w-full"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            City
          </label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            className="input-primary w-full"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            State
          </label>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
            className="input-primary w-full"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            ZIP Code
          </label>
          <input
            type="text"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleInputChange}
            className="input-primary w-full"
            required
          />
        </div>
      </div>

      <div className="space-y-6">
        <h3 className="text-lg font-medium text-gray-200">Availability</h3>
        {Object.entries(formData.availability).map(([day, slots]) => (
          <div key={day} className="p-4 rounded-lg bg-dark-700">
            <div className="flex items-center justify-between mb-4">
              <h4 className="text-sm font-medium text-gray-300 capitalize">{day}</h4>
              <button
                type="button"
                onClick={() => addTimeSlot(day as keyof Court['availability'])}
                className="p-1 rounded-lg hover:bg-dark-600 transition-colors"
              >
                <PlusIcon className="w-5 h-5 text-primary-400" />
              </button>
            </div>
            <div className="space-y-3">
              {slots.map((slot, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <input
                    type="time"
                    value={slot.start}
                    onChange={(e) => handleTimeSlotChange(day as keyof Court['availability'], index, 'start', e.target.value)}
                    className="input-primary"
                    required
                  />
                  <span className="text-gray-400">to</span>
                  <input
                    type="time"
                    value={slot.end}
                    onChange={(e) => handleTimeSlotChange(day as keyof Court['availability'], index, 'end', e.target.value)}
                    className="input-primary"
                    required
                  />
                  {slots.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeTimeSlot(day as keyof Court['availability'], index)}
                      className="p-1 rounded-lg hover:bg-dark-600 transition-colors"
                    >
                      <XMarkIcon className="w-5 h-5 text-red-400" />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="btn-secondary"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn-primary"
        >
          {court ? 'Update Court' : 'Add Court'}
        </button>
      </div>
    </form>
  );
};
