import React from 'react';
import { Match } from '../../types/match';
import { CalendarIcon, MapPinIcon, UserGroupIcon, ClockIcon, TrophyIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

interface MatchCardProps {
  match: Match;
  onEditOutcome: (match: Match) => void;
}

const ScoreDisplay = ({ score }: { score: string }) => {
  const sets = score.split(', ').map(set => {
    const [score1, score2] = set.split('-').map(Number);
    return { score1, score2, winner: score1 > score2 ? 1 : 2 };
  });

  return (
    <div className="flex space-x-2">
      {sets.map((set, idx) => (
        <div key={idx} className="flex items-center space-x-1">
          <div className={`px-2 py-1 rounded ${
            set.winner === 1 
              ? 'bg-primary-500/20 text-primary-400 font-medium' 
              : 'bg-dark-700 text-gray-400'
          }`}>
            {set.score1}
          </div>
          <div className={`px-2 py-1 rounded ${
            set.winner === 2 
              ? 'bg-primary-500/20 text-primary-400 font-medium' 
              : 'bg-dark-700 text-gray-400'
          }`}>
            {set.score2}
          </div>
          {idx < sets.length - 1 && (
            <span className="text-gray-600">•</span>
          )}
        </div>
      ))}
    </div>
  );
};

export const MatchCard: React.FC<MatchCardProps> = ({ match, onEditOutcome }) => {
  const navigate = useNavigate();

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }).format(date);
  };

  const formatTime = (dateStr: string) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };

  const getWinnerName = (match: Match) => {
    if (!match.outcome) return null;
    
    if (match.type === 'singles') {
      return match.outcome.winner === 'player1' ? match.player1?.name : match.player2?.name;
    } else {
      return match.outcome.winner === 'team1' 
        ? match.team1?.players.map(p => p.name).join(', ')
        : match.team2?.players.map(p => p.name).join(', ');
    }
  };

  return (
    <div 
      className="card p-6 hover:border-primary-500/50 transition-colors cursor-pointer group"
      onClick={() => navigate(`/matches/${match.id}`)}
    >
      <div className="flex items-center justify-between mb-4">
        <span className={`px-3 py-1 rounded-full text-xs font-medium ${
          match.type === 'singles'
            ? 'bg-blue-500/20 text-blue-400'
            : 'bg-purple-500/20 text-purple-400'
        }`}>
          {match.type === 'singles' ? 'Singles Match' : 'Doubles Match'}
        </span>
        <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${
          match.status === 'completed' 
            ? 'bg-emerald-400/10 text-emerald-400'
            : match.status === 'in_progress'
            ? 'bg-yellow-400/10 text-yellow-400'
            : 'bg-blue-400/10 text-blue-400'
        }`}>
          {match.status.charAt(0).toUpperCase() + match.status.slice(1).replace('_', ' ')}
        </span>
      </div>

      <div className="space-y-4">
        <div className="flex items-start space-x-3">
          <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-primary-500/10 flex items-center justify-center">
            <CalendarIcon className="w-5 h-5 text-primary-400" />
          </div>
          <div>
            <p className="text-gray-300 font-medium">{formatDate(match.startTime)}</p>
            <div className="flex items-center space-x-2 mt-1">
              <ClockIcon className="w-4 h-4 text-gray-500" />
              <p className="text-gray-500 text-sm">{formatTime(match.startTime)}</p>
            </div>
          </div>
        </div>

        <div className="flex items-start space-x-3">
          <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-blue-500/10 flex items-center justify-center">
            <MapPinIcon className="w-5 h-5 text-blue-400" />
          </div>
          <div>
            <p className="text-gray-300 font-medium">{match.court}</p>
            <p className="text-gray-500 text-sm mt-1">{match.address}</p>
          </div>
        </div>

        <div className="flex items-start space-x-3">
          <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-purple-500/10 flex items-center justify-center">
            <UserGroupIcon className="w-5 h-5 text-purple-400" />
          </div>
          <div className="flex-1">
            {match.type === 'singles' ? (
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <p className="text-gray-300">{match.player1?.name}</p>
                  <div className="w-6 h-px bg-dark-700 my-2"></div>
                  <p className="text-gray-300">{match.player2?.name}</p>
                </div>
                {match.outcome && (
                  <div className="ml-4">
                    <ScoreDisplay score={match.outcome.score} />
                  </div>
                )}
              </div>
            ) : (
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-gray-500 text-sm">Team 1</p>
                    <p className="text-gray-300 mt-1">{match.team1?.players.map(p => p.name).join(', ')}</p>
                  </div>
                  {match.outcome && (
                    <div className="ml-4">
                      <ScoreDisplay score={match.outcome.score} />
                    </div>
                  )}
                </div>
                <div className="w-full h-px bg-dark-700"></div>
                <div>
                  <p className="text-gray-500 text-sm">Team 2</p>
                  <p className="text-gray-300 mt-1">{match.team2?.players.map(p => p.name).join(', ')}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        {match.status === 'completed' && (
          <div className="flex items-start space-x-3">
            <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-yellow-500/10 flex items-center justify-center">
              <TrophyIcon className="w-5 h-5 text-yellow-400" />
            </div>
            <div className="flex-1">
              {match.outcome ? (
                <div>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-gray-300 font-medium">Winner</p>
                      <p className="text-primary-400 mt-1">{getWinnerName(match)}</p>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditOutcome(match);
                      }}
                      className="p-2 rounded-lg hover:bg-dark-700 transition-colors group"
                    >
                      <PencilIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-300" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  <p className="text-gray-500">No outcome recorded</p>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditOutcome(match);
                    }}
                    className="btn-primary btn-sm"
                  >
                    Add Outcome
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
