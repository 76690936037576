import { Match, CreateMatchDTO, UpdateMatchOutcomeDTO, UpdateMatchStatusDTO, MatchStatus } from '../types/match';

// Mock data
const mockMatches: Match[] = [
  {
    id: '1',
    type: 'singles',
    status: 'completed',
    startTime: '2024-02-10T14:00:00',
    court: 'Court A1',
    address: '123 Pickleball Ave, Sports Center',
    player1: { id: '1', name: 'John Smith' },
    player2: { id: '2', name: 'Sarah Johnson' },
    createdAt: '2024-02-01T10:00:00',
    updatedAt: '2024-02-01T10:00:00',
    outcome: {
      winner: 'player1',
      score: '21-18, 21-19'
    }
  },
  {
    id: '2',
    type: 'doubles',
    status: 'in_progress',
    startTime: '2024-02-11T15:30:00',
    court: 'Court B2',
    address: '456 Sports Complex Dr',
    team1: {
      id: '1',
      players: [
        { id: '3', name: 'Mike Wilson' },
        { id: '4', name: 'Tom Brown' }
      ]
    },
    team2: {
      id: '2',
      players: [
        { id: '5', name: 'Lisa Davis' },
        { id: '6', name: 'Emma White' }
      ]
    },
    createdAt: '2024-02-01T11:00:00',
    updatedAt: '2024-02-01T11:00:00'
  },
  {
    id: '3',
    type: 'singles',
    status: 'upcoming',
    startTime: '2024-02-12T10:00:00',
    court: 'Court C3',
    address: '789 Recreation Center',
    player1: { id: '7', name: 'David Lee' },
    player2: { id: '8', name: 'Anna Chen' },
    createdAt: '2024-02-01T12:00:00',
    updatedAt: '2024-02-01T12:00:00'
  }
];

// Initialize matches with mock data
let matches: Match[] = [...mockMatches];

export const matchService = {
  createMatch: async (matchData: CreateMatchDTO): Promise<Match> => {
    const newMatch: Match = {
      id: Math.random().toString(36).substr(2, 9),
      ...matchData,
      status: 'upcoming',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    matches.push(newMatch);
    return newMatch;
  },

  getMatches: async (): Promise<Match[]> => {
    return matches;
  },

  getMatch: async (id: string): Promise<Match | undefined> => {
    return matches.find(match => match.id === id);
  },

  updateMatchStatus: async (id: string, { status }: UpdateMatchStatusDTO): Promise<Match | undefined> => {
    const index = matches.findIndex(match => match.id === id);
    if (index === -1) return undefined;

    matches[index] = {
      ...matches[index],
      status,
      updatedAt: new Date().toISOString(),
    };
    return matches[index];
  },

  updateMatchOutcome: async (id: string, outcomeData: UpdateMatchOutcomeDTO): Promise<Match | undefined> => {
    const index = matches.findIndex(match => match.id === id);
    if (index === -1) return undefined;

    matches[index] = {
      ...matches[index],
      outcome: outcomeData,
      status: 'completed',
      updatedAt: new Date().toISOString(),
    };
    return matches[index];
  },

  deleteMatch: async (id: string): Promise<boolean> => {
    const initialLength = matches.length;
    matches = matches.filter(match => match.id !== id);
    return initialLength > matches.length;
  },
};
