import React, { useState, useEffect } from 'react';
import { Team, TeamFormData } from '../types/team';
import { teamService } from '../services/teamService';
import { TeamCard } from '../components/teams/TeamCard';
import { TeamForm } from '../components/teams/TeamForm';
import { MagnifyingGlassIcon, PlusIcon, FunnelIcon } from '@heroicons/react/24/outline';

export const Teams: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editingTeam, setEditingTeam] = useState<Team | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<'all' | 'active' | 'inactive'>('all');
  const [teamToDelete, setTeamToDelete] = useState<Team | null>(null);

  const loadTeams = async () => {
    setLoading(true);
    try {
      const filters: { status?: 'active' | 'inactive'; search?: string } = {};
      if (statusFilter !== 'all') {
        filters.status = statusFilter;
      }
      if (searchTerm) {
        filters.search = searchTerm;
      }
      const fetchedTeams = await teamService.getTeams(filters);
      setTeams(fetchedTeams);
    } catch (error) {
      console.error('Error loading teams:', error);
      setError('Failed to load teams. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTeams();
  }, [searchTerm, statusFilter]);

  const handleCreateTeam = async (data: TeamFormData) => {
    try {
      setActionInProgress(true);
      await teamService.createTeam(data);
      setShowForm(false);
      loadTeams();
    } catch (error) {
      console.error('Error creating team:', error);
      throw new Error('Failed to create team');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleUpdateTeam = async (data: TeamFormData) => {
    if (!editingTeam) return;
    try {
      setActionInProgress(true);
      await teamService.updateTeam(editingTeam.id, data);
      setEditingTeam(null);
      loadTeams();
    } catch (error) {
      console.error('Error updating team:', error);
      throw new Error('Failed to update team');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleDeleteClick = (team: Team) => {
    setTeamToDelete(team);
  };

  const handleDeleteConfirm = async () => {
    if (!teamToDelete) return;
    try {
      setActionInProgress(true);
      await teamService.deleteTeam(teamToDelete.id);
      await loadTeams();
      setTeamToDelete(null);
    } catch (error) {
      console.error('Error deleting team:', error);
      setError('Failed to delete team. Please try again.');
    } finally {
      setActionInProgress(false);
    }
  };

  if (showForm || editingTeam) {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold text-gray-100">
            {editingTeam ? 'Edit Team' : 'Create New Team'}
          </h1>
        </div>
        <div className="card p-6">
          <TeamForm
            onSubmit={editingTeam ? handleUpdateTeam : handleCreateTeam}
            initialData={editingTeam || undefined}
            onCancel={() => {
              setShowForm(false);
              setEditingTeam(null);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-100">Teams</h1>
        <button
          onClick={() => setShowForm(true)}
          className="btn-primary flex items-center space-x-2"
          disabled={actionInProgress}
        >
          <PlusIcon className="w-5 h-5" />
          <span>Add Team</span>
        </button>
      </div>

      {/* Filters */}
      <div className="card p-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <div className="relative">
              <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search teams..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="input pl-10"
              />
            </div>
          </div>
          <div className="sm:w-48">
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value as any)}
              className="input"
            >
              <option value="all">All Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="rounded-lg bg-red-500/10 p-4 text-red-400">
          {error}
          <button
            onClick={loadTeams}
            className="ml-2 underline hover:text-red-300"
          >
            Try again
          </button>
        </div>
      )}

      {/* Content */}
      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500 mx-auto"></div>
          <p className="mt-4 text-gray-400">Loading teams...</p>
        </div>
      ) : teams.length === 0 ? (
        <div className="text-center py-12">
          <div className="w-16 h-16 bg-dark-700 rounded-full flex items-center justify-center mx-auto mb-4">
            <FunnelIcon className="w-8 h-8 text-gray-400" />
          </div>
          <h3 className="text-lg font-medium text-gray-300">No teams found</h3>
          <p className="text-gray-400 mt-2">Try adjusting your filters or add a new team.</p>
          <button
            onClick={() => setShowForm(true)}
            className="btn-primary mt-6"
          >
            Add Your First Team
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {teams.map((team) => (
            <TeamCard
              key={team.id}
              team={team}
              onEdit={setEditingTeam}
              onDelete={handleDeleteClick}
            />
          ))}
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {teamToDelete && (
        <div className="fixed inset-0 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
          <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-md p-6 animate-fadeInUp">
            <h3 className="text-lg font-medium text-gray-100 mb-4">Delete Team</h3>
            <p className="text-gray-400">
              Are you sure you want to delete {teamToDelete.name}? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3 mt-6">
              <button
                onClick={() => setTeamToDelete(null)}
                className="btn-secondary"
                disabled={actionInProgress}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors relative"
                disabled={actionInProgress}
              >
                {actionInProgress ? (
                  <>
                    <span className="opacity-0">Delete</span>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-5 h-5 border-t-2 border-r-2 border-white rounded-full animate-spin"></div>
                    </div>
                  </>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
