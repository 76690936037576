import React, { useState, useEffect, useCallback } from 'react';
import { Court } from '../types/court';
import { courtService } from '../services/courtService';
import { CourtCard } from '../components/courts/CourtCard';
import { CourtForm } from '../components/courts/CourtForm';
import { CourtMap } from '../components/courts/CourtMap';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  FunnelIcon,
  MapIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';

type ViewMode = 'grid' | 'map';

export default function Courts() {
  const [courts, setCourts] = useState<Court[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>('map');
  
  // Filters
  const [searchQuery, setSearchQuery] = useState('');
  
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourt, setSelectedCourt] = useState<Court | undefined>(undefined);
  
  // Delete confirmation
  const [courtToDelete, setCourtToDelete] = useState<Court | null>(null);

  const loadCourts = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await courtService.getCourts();
      const filteredCourts = data.filter(court => 
        court.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        court.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        court.city.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setCourts(filteredCourts);
    } catch (err) {
      setError('Failed to load courts. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    loadCourts();
  }, [loadCourts]);

  const handleAddCourt = () => {
    setSelectedCourt(undefined);
    setIsModalOpen(true);
  };

  const handleEditCourt = (court: Court) => {
    setSelectedCourt(court);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (court: Court) => {
    setCourtToDelete(court);
  };

  const handleDeleteConfirm = async () => {
    if (!courtToDelete) return;
    
    try {
      setActionInProgress(true);
      await courtService.deleteCourt(courtToDelete.id);
      await loadCourts();
      setCourtToDelete(null);
    } catch (err) {
      setError('Failed to delete court. Please try again.');
      console.error(err);
    } finally {
      setActionInProgress(false);
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      setActionInProgress(true);
      if (selectedCourt) {
        await courtService.updateCourt(selectedCourt.id, data);
      } else {
        await courtService.createCourt(data);
      }
      await loadCourts();
      setIsModalOpen(false);
      setSelectedCourt(undefined);
    } catch (err) {
      throw new Error(selectedCourt ? 'Failed to update court' : 'Failed to create court');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleCloseModal = () => {
    if (!actionInProgress) {
      setIsModalOpen(false);
      setSelectedCourt(undefined);
    }
  };

  const toggleViewMode = () => {
    setViewMode(current => current === 'grid' ? 'map' : 'grid');
  };

  return (
    <>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold text-gray-100">Courts</h1>
          <div className="flex items-center space-x-3">
            <button
              onClick={toggleViewMode}
              className="btn-secondary flex items-center space-x-2"
            >
              {viewMode === 'grid' ? (
                <>
                  <MapIcon className="w-5 h-5" />
                  <span>Map View</span>
                </>
              ) : (
                <>
                  <ViewColumnsIcon className="w-5 h-5" />
                  <span>Grid View</span>
                </>
              )}
            </button>
            <button
              onClick={handleAddCourt}
              className="btn-primary flex items-center space-x-2"
              disabled={actionInProgress}
            >
              <PlusIcon className="w-5 h-5" />
              <span>Add Court</span>
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="card p-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search courts..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="input pl-10"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="rounded-lg bg-red-500/10 p-4 text-red-400">
            {error}
            <button
              onClick={loadCourts}
              className="ml-2 underline hover:text-red-300"
            >
              Try again
            </button>
          </div>
        )}

        {/* Loading State */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500 mx-auto"></div>
            <p className="mt-4 text-gray-400">Loading courts...</p>
          </div>
        ) : courts.length === 0 ? (
          <div className="text-center py-12">
            <div className="w-16 h-16 bg-dark-700 rounded-full flex items-center justify-center mx-auto mb-4">
              <FunnelIcon className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-300">No courts found</h3>
            <p className="text-gray-400 mt-2">Try adjusting your search or add a new court.</p>
            <button
              onClick={handleAddCourt}
              className="btn-primary mt-6"
            >
              Add Your First Court
            </button>
          </div>
        ) : viewMode === 'grid' ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {courts.map((court) => (
              <CourtCard
                key={court.id}
                court={court}
                onEdit={handleEditCourt}
                onDelete={handleDeleteClick}
              />
            ))}
          </div>
        ) : (
          <div className="h-[calc(100vh-280px)] min-h-[500px]">
            <CourtMap
              courts={courts}
              onCourtClick={handleEditCourt}
            />
          </div>
        )}
      </div>

      {/* Modals Container */}
      <div className="relative z-50">
        {/* Add/Edit Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
            <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-4xl p-6 max-h-[90vh] overflow-y-auto">
              <h2 className="text-xl font-semibold text-gray-100 mb-6">
                {selectedCourt ? 'Edit Court' : 'Add New Court'}
              </h2>
              <CourtForm
                court={selectedCourt}
                onSubmit={handleSubmit}
                onCancel={handleCloseModal}
              />
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
        {courtToDelete && (
          <div className="fixed inset-0 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
            <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-md p-6 animate-fadeInUp">
              <h3 className="text-lg font-medium text-gray-100 mb-4">Delete Court</h3>
              <p className="text-gray-400">
                Are you sure you want to delete {courtToDelete.name}? This action cannot be undone.
              </p>
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => setCourtToDelete(null)}
                  className="btn-secondary"
                  disabled={actionInProgress}
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteConfirm}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors relative"
                  disabled={actionInProgress}
                >
                  {actionInProgress ? (
                    <>
                      <span className="opacity-0">Delete</span>
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-5 h-5 border-t-2 border-r-2 border-white rounded-full animate-spin"></div>
                      </div>
                    </>
                  ) : (
                    'Delete'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
