import React from 'react';
import { Team } from '../../types/team';
import { UserGroupIcon, TrophyIcon, ChartBarIcon, HashtagIcon } from '@heroicons/react/24/outline';

interface TeamCardProps {
  team: Team;
  onEdit?: (team: Team) => void;
  onDelete?: (team: Team) => void;
}

export const TeamCard: React.FC<TeamCardProps> = ({ team, onEdit, onDelete }) => {
  const winRate = team.wins + team.losses > 0 
    ? ((team.wins / (team.wins + team.losses)) * 100).toFixed(1)
    : '0.0';

  return (
    <div className="card p-6 hover:border-primary-500/50 transition-colors duration-300">
      <div className="flex items-start justify-between">
        <div>
          <h3 className="text-lg font-medium text-gray-100">{team.name}</h3>
          <div className="flex items-center space-x-2 mt-1">
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              team.status === 'active' 
                ? 'bg-emerald-400/10 text-emerald-400' 
                : 'bg-red-400/10 text-red-400'
            }`}>
              {team.status.charAt(0).toUpperCase() + team.status.slice(1)}
            </span>
            {team.division && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-400/10 text-primary-400">
                {team.division}
              </span>
            )}
          </div>
        </div>
        <div className="flex space-x-2">
          {onEdit && (
            <button
              onClick={() => onEdit(team)}
              className="btn-secondary text-sm px-3 py-1"
            >
              Edit
            </button>
          )}
          {onDelete && (
            <button
              onClick={() => onDelete(team)}
              className="px-3 py-1 bg-red-500/10 text-red-400 rounded-lg hover:bg-red-500/20 transition-colors duration-200 text-sm"
            >
              Delete
            </button>
          )}
        </div>
      </div>

      {team.description && (
        <p className="mt-4 text-sm text-gray-400">{team.description}</p>
      )}

      <div className="mt-6 space-y-3">
        <div className="flex items-center space-x-2 text-gray-400">
          <UserGroupIcon className="w-4 h-4" />
          <span className="text-sm">{team.members.length} Members</span>
        </div>
        <div className="flex items-center space-x-2 text-gray-400">
          <TrophyIcon className="w-4 h-4" />
          <span className="text-sm">{team.wins}W - {team.losses}L</span>
        </div>
        <div className="flex items-center space-x-2 text-gray-400">
          <ChartBarIcon className="w-4 h-4" />
          <span className="text-sm">Win Rate: {winRate}%</span>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-4 gap-4">
        <div className="text-center">
          <div className="text-2xl font-semibold text-emerald-400">
            {team.wins}
          </div>
          <div className="text-xs text-gray-400 mt-1">Wins</div>
        </div>
        <div className="text-center">
          <div className="text-2xl font-semibold text-red-400">
            {team.losses}
          </div>
          <div className="text-xs text-gray-400 mt-1">Losses</div>
        </div>
        <div className="text-center">
          <div className="text-2xl font-semibold text-yellow-400">
            {team.totalPoints}
          </div>
          <div className="text-xs text-gray-400 mt-1">Points</div>
        </div>
        <div className="text-center">
          <div className="text-2xl font-semibold text-primary-400">
            {team.members.length}
          </div>
          <div className="text-xs text-gray-400 mt-1">Members</div>
        </div>
      </div>
    </div>
  );
};
